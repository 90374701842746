export interface GeocodeResultFromLocation {
  place_id: string
  formatted_address: string
  description: string
  lat: number
  lng: number
}

export interface GeocodeResultFromCoords {
  place_id: string
  formatted_address: string
  description: string
}
export const reverseGeocode = (lat, lng): Promise<GeocodeResultFromCoords> => {
    // console.log(window.google)
    return new Promise((resolve, reject) => {
        if (!window.google) {
            reject(new Error('Google Maps API not loaded'))
            return
        }

        const geocoder = new window.google.maps.Geocoder()
        const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) }

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const formatted_address = results[0].formatted_address
                    const place_id = results[0].place_id
                    const description = results[0].types.join(', ')

                    resolve({ formatted_address, place_id, description })
                } else {
                    reject(new Error('No results found'))
                }
            } else {
                reject(new Error('Geocoder failed due to: ' + status))
            }
        })
    })
}

export const geocodeByLocation = (locationName: string): Promise<GeocodeResultFromLocation> => {
    return new Promise((resolve, reject) => {
        if (!window.google) {
            reject(new Error('Google Maps API not loaded'))
            return
        }

        const geocoder = new window.google.maps.Geocoder()

        geocoder.geocode({ address: locationName }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const formatted_address = results[0].formatted_address
                    const place_id = results[0].place_id
                    const description = results[0].types.join(', ')
                    const lat = results[0].geometry.location.lat()
                    const lng = results[0].geometry.location.lng()

                    resolve({ formatted_address, place_id, description, lat, lng })
                } else {
                    reject(new Error('No results found'))
                }
            } else {
                reject(new Error('Geocoder failed due to: ' + status))
            }
        })
    })
}
